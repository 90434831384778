<template>
  <div>
    <el-form
        :model="query"
        :inline="true"
    >
      <el-form-item label="语言">
        <el-select
            multiple
            v-model="auditSelectLangs"
            clearable
            placeholder="语言"
        >
          <el-option
              v-for="item in langOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button v-if="auditStatus === 0" @click="updateAuditStatus(1)" type="primary">开始审核</el-button>
        <el-button v-if="auditStatus === 1" @click="exitAuth" type="danger">退出审核</el-button>
        <el-button v-if="auditStatus === 1" @click="refresh" type="primary" icon="el-icon-refresh">刷新</el-button>


      </el-form-item>
    </el-form>

    <span
        v-if="countShow">今日进审数量 {{ reportCount.todayReportCount }}，今日审核数量 {{ reportCount.todayHandleCount }}，当前积压总量 {{ reportCount.totalUnHandleCount }}，今日进审且积压量 {{ reportCount.totalUnHandleCount }}</span>

    <el-form class="info" :model="showData" v-if="show">
      <el-row>
        <el-col :span="6">
          <h3>【被举报人信息】</h3>
          <el-form-item>
            <img v-if="showData.userOfAudit" :src="showData.userOfAudit.avatar" class="avatar">
          </el-form-item>
          <el-form-item label="uid：">
            {{ this.showData.userOfAudit.uid }}
          </el-form-item>
          <el-form-item label="user code：">
            {{ this.showData.userOfAudit.ucode }}
          </el-form-item>
          <el-form-item label="昵称：">
            {{ this.showData.userOfAudit.nickname }}
          </el-form-item>
          <el-form-item label="注册时间：">
            {{ this.showData.userOfAudit.createTime }}
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <h3>【举报人信息】</h3>
          <el-form-item>
            <img v-if="showData.reportUserEntity" :src="showData.reportUserEntity.avatar" class="avatar">
          </el-form-item>
          <el-form-item label="uid：">
            {{ this.showData.reportUserEntity.uid }}
          </el-form-item>
          <el-form-item label="user code：">
            {{ this.showData.reportUserEntity.ucode }}
          </el-form-item>
          <el-form-item label="昵称：">
            {{ this.showData.reportUserEntity.nickname }}
          </el-form-item>
          <el-form-item label="注册时间：">
            {{ this.showData.reportUserEntity.createTime }}
          </el-form-item>
        </el-col>


        <el-col :span="12">
          <el-form-item label="审核单ID：" prop="id">
            {{ showData.id }}
          </el-form-item>
          <el-form-item label="举报原因：" prop="description">
            {{ showData.description }}
          </el-form-item>

          <el-form-item label="举报时间:">
            {{ this.showData.createTime }}
          </el-form-item>

          <el-form-item label="证据截图：">
          </el-form-item>

          <el-row :gutter="1">
            <el-col :span="8" v-for="(item, index) in showData.images" :key="index">
              <ImageView
                  style="width: 100px"
                  :src="item"
                  :preview-src-list=[item]>
              </ImageView>
            </el-col>
          </el-row>

        </el-col>
      </el-row>
    </el-form>

    <el-form
        ref="submitForm"
        :model="auditHandleForm"
        v-if="show"
    >
      <el-form-item
          label="处理方式："
          prop="handleMode">
        <el-radio-group v-model="auditHandleForm.handleMode">
          <el-radio v-for="item in handleMoles"
                    :key="item.handleMode" :label="item.handleMode" border>
            {{ item.desc }}
          </el-radio>
        </el-radio-group>
      </el-form-item>


      <el-form-item>
        <div class="submit-btn">
<!--          <el-button type="warning" @click="ignoreCurrent">跳过</el-button>-->
          <el-button type="primary" @click="handleAudit">提交</el-button>
        </div>
      </el-form-item>
    </el-form>

  </div>

</template>

<script>
import ImageView from "@/views/modules/audit/ImageView";

const defaultShowData = {
  id: 0,
  auditType: -1,
  reportUserEntity: {
    uid: 0,
    nickname: '',
    avatar: '',
    ucode: ''
  },
  userOfAudit: {
    uid: 0,
    nickname: '',
    avatar: '',
    ucode: ''
  },
  description: '',
  images: [],
  createTime: ''
}


export default {
  name: 'AuditBase',

  props: {
    // TODO
    auditTypes: Array,

    // [{handleMode: xxx, desc: xxxx}]
    handleMoles: Array,
    defaultHandleMole: String,
    title: String

  },
  data() {

    return {
      show: false,
      countShow: false,
      auditStatus: 0,
      auditSelectLangs: [],
      query: {
        auditTypes: this.auditTypes,
        ignoreIds: []
      },
      auditHandleForm: {
        reportId: 0,
        handleMode: 'IGNORE'
      },
      showData: JSON.parse(JSON.stringify(defaultShowData)),
      reportCount: {
        todayReportCount: 0,
        todayHandleCount: 0,
        todayUnHandleCount: 0,
        totalUnHandleCount: 0
      },
      langOptions: [
        {
          value: 'ar',
          label: '阿拉伯语'
        },
        {
          value: 'en',
          label: '英语'
        }
      ],
    };
  },
  components: {
    ImageView
  },
  created() {
    this.handleMoles.push({
      handleMode: 'SKIP',
      desc: '跳过'
    })
    this.auditSelectLangs = JSON.parse(sessionStorage.getItem('auditSelectLangs')) || []
    this.queryAuditStatus()
  },
  watch: {
    auditSelectLangs (newVal) {
      sessionStorage.setItem('auditSelectLangs', JSON.stringify(newVal))
      this.queryAuditStatus()
    }
  },
  computed: {
    userId: {
      get () { return this.$store.state.user.id }
    },
  },

  methods: {
    queryAuditStatus(){
      this.$http({
        url: this.$http.adornUrl('/sys/user/auditStatus'),
        method: 'get',
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.auditStatus = data.result
          if(this.auditStatus === 1){
            // 已经在审核状态中
            this.auditShow();
          } else {
            this.show = false
            this.countShow = false
          }
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })
    },
    showOrRefreshCount() {
      this.$http({
        url: this.$http.adornUrl('/admin/audit/auditCount'),
        method: 'post',
        data: this.$http.adornParams({
          auditTypes: this.query.auditTypes
        }),
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.reportCount = data.result
          this.countShow = true;
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })

    },
    handleAudit() {
      this.auditHandleForm.reportId = this.showData.id
      this.$refs['submitForm'].validate((valid) => {
        if (valid) {

          if(this.auditHandleForm.handleMode === 'SKIP'){
            console.log("跳过审核单")
            this.ignoreCurrent()
          } else {

            this.$http({
              url: this.$http.adornUrl('/admin/audit/handleAudit'),
              method: 'post',
              data: this.$http.adornParams({
                reportId: this.auditHandleForm.reportId,
                handleMode: this.auditHandleForm.handleMode
              }),
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "审核成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.auditShow()
                  },
                });
              } else {
                this.$message({
                  message: data.msg,
                  type: 'error'
                })
              }
            })
          }
        }
      })

    },
    exitAuth(){
      this.$http({
        url: this.$http.adornUrl('/sys/user/updAuditStatus'),
        method: 'post',
        data: this.$http.adornParams({
          auditStatus: 0,
        }),
      }).then(({data}) => {
        if (data && data.code === 0) {
          // 退出页面
          this.$eventBus.$emit('delTab', this.$router.currentRoute.name);
          console.log('sss',this.$router.currentRoute.name)
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })

        }
      })
    },
    updateAuditStatus(status){
      this.$http({
        url: this.$http.adornUrl('/sys/user/updAuditStatus'),
        method: 'post',
        data: this.$http.adornParams({
          auditStatus: status,
        }),
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.queryAuditStatus()
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })

        }
      })
    },
    auditShow() {
      this.showOrRefreshCount()
      this.$http({
        url: this.$http.adornUrl('/admin/audit/auditShow'),
        method: 'post',
        data: this.$http.adornParams({
          auditTypes: this.query.auditTypes,
          ignoreIds: this.query.ignoreIds,
          langs: this.auditSelectLangs
        }),
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.showData = data.result
          if(data.result.tips && data.result.tips !== ''){
            this.$message({
              message: data.result.tips,
              type: 'error'
            })
          }
          this.show = true;
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
          this.showData = JSON.parse(JSON.stringify(defaultShowData))
          this.show = false
        }
      })
    },
    ignoreCurrent() {

      this.$http({
        url: this.$http.adornUrl('/admin/audit/skipAudit'),
        method: 'post',
        data: this.$http.adornParams({
          reportId: this.showData.id
        }),
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.auditShow();
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })
    },
    refresh() {
      this.queryAuditStatus()
    }
  }
}
</script>

<style scoped lang="scss">
.avatar {
  width: 100px;
  height: 100px;
}

.info {
  margin-top: 20px;
}

.submit-btn {
  text-align: center;
}
</style>

